<template>
  <v-container fluid class="ma-0 pa-0 background">
    <v-card class="mx-auto elevation-0 px-2 pb-3 mb-4 background" flat>
      <UpgradeBanner
        v-if="
          !firstSyncUsersStatus?.in_sync_process && !missingScopesSuite.length
        "
      />

      <!-- SCOPE ALERT  -->
      <Alert
        v-if="missingScopesSuite.length"
        class="mt-4 mb-4 mb-0"
        :prominent="!$vuetify.breakpoint.mobile"
        color="warning"
        outlined
      >
        <h3 class="text-h5">
          <strong>{{ $t("scopesAlert.alertTitle") }}</strong>
        </h3>
        <span>
          {{ $t("scopesAlert.alertMessage1") }}
          <span class="font-weight-medium">Conecta Suite</span>.
        </span>
        <br />
        <span v-if="isAdmin">
          {{ $t("scopesAlert.alertMessage2") }}
        </span>
        <span v-else>
          {{ $t("scopesAlert.alertMessage3") }}
        </span>
        <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
        <v-row class="ma-0 pa-0" no-gutters align="center">
          <v-col lg="9" sm="12" md="9" class="pa-0 grow">
            <span v-html="$t('scopesAlert.alertMessage4')"> </span>
          </v-col>

          <v-col lg="3" sm="12" md="3" class="pa-0 shrink d-flex justify-end">
            <v-btn
              text
              small
              color="secondary lighten-2"
              href="https://conectasuite.com/perguntas-frequentes/como-permitir-acesso-a-novos-escopos-no-painel-do-google-administrativo-2/"
              target="_blank"
              class="mr-2 text-body-2 font-weight-medium"
            >
              {{ $t("action.knowMore") }}
            </v-btn>
            <v-btn
              v-if="isAdmin"
              text
              small
              outlined
              color="warning"
              :href="missingScopesText"
              target="_blank"
              class="ml-auto text-body-2 font-weight-medium"
            >
              {{ $t("action.acceptScopes") }}
            </v-btn>
          </v-col>
        </v-row>
      </Alert>
      <!-- SYNC USERS ALERT  -->
      <Alert
        v-if="!!firstSyncUsersStatus.in_sync_process"
        class="mt-4 mb-4 mb-0"
        :prominent="!$vuetify.breakpoint.mobile"
        color="info"
        outlined
      >
        <br />
        <h4 v-if="isAdmin">{{ $t("syncUsers.syncUsers") }}:</h4>
        <v-progress-linear
          color="primary"
          class="my-5 white--text"
          :value="progress"
          height="25"
          rounded
        >
          <strong>
            {{
              `${$t("syncUsers.synchronizingUsers")}: ${
                firstSyncUsersStatus.total_users_created
              } ${$t("common.of")} ${firstSyncUsersStatus.total_users}`
            }}
          </strong>
        </v-progress-linear>
      </Alert>

      <!-- ON BOARDING DIALOG BY PRODUCT -->
      <v-row
        :class="`align-center ma-0 py-4 px-0 background`"
        align-content="center"
        justify-lg="start"
        justify-xs="center"
      >
        <v-col
          cols="12"
          xs="12"
          md="3"
          lg="3"
          xl="2"
          v-for="(card, i) in cards"
          :key="i"
        >
          <v-card
            hover
            id="v-step-0"
            @click.stop="
              showOnboardingModuleOrSetPath(card.key, card.path, card.title)
            "
            class="pt-6 pb-3 card"
            height="255"
            width="100%"
            min-width="200"
            outlined
            :disabled="loading"
          >
            <div v-if="card.type == 'icon'">
              <v-icon
                size="65"
                :class="`d-flex justify-center align-start mb-3`"
                color="accent"
                >{{ card.icon }}</v-icon
              >
            </div>

            <div :class="`d-flex justify-center align-start mb-1`">
              <Logo
                no-margins
                width="65"
                :height="isProduct(card.name) ? '55' : '65'"
                :product="card.name"
              />
            </div>

            <h3 class="text-center px-2" v-if="card.title">
              <span>{{ card.title }}</span>
              <span v-if="card.beta">
                <v-chip
                  color="blue"
                  dark
                  :title="$t('common.availableIndefinitely')"
                  x-small
                  class="ml-2 font-weight-medium"
                  >Beta
                </v-chip>
              </span>
            </h3>

            <v-card-subtitle class="pb-0 text-center px-4">{{
              card.description
            }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <FirstTimeOnBoarding />
    <TourOnBoarding />
  </v-container>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import Logo from "@/components/base/Logo";
import FirstTimeOnBoarding from "@/components/main/onBoarding/FirstTimeOnBoarding.vue";
import TourOnBoarding from "@/components/main/onBoarding/TourOnBoarding.vue";
import { mapGetters } from "vuex";
import UpgradeBanner from "@/components/billing/upgrade/UpgradeBanner.vue";
import Alert from "@/components/base/Alert.vue";
import {
  modules,
  modulesDetails,
  cardsName,
} from "@/helpers/variables/modules";
import { goToControl } from "@/helpers/services/utils";
import { isProduct } from "@/helpers/services/products";

export default {
  name: "Home",
  components: {
    FirstTimeOnBoarding,
    TourOnBoarding,
    Logo,
    Alert,
    UpgradeBanner,
  },
  data: () => ({
    modulesDetails,
    modules,
    cardsName,
  }),

  computed: {
    ...mapGetters([
      "isReseller",
      "missingScopesSuite",
      "company",
      "hasConectaTrack",
      "isAdmin",
      "hasContractedPlans",
      "loading",
      "firstSyncUsersStatus",
    ]),

    cards() {
      return [
        {
          name: modules.users,
          title: this.$t(cardsName.users),
          description: this.$t(modulesDetails.users.short_description),
          path: "/users",
          key: modules.users,
        },
        {
          name: modules.workspace_diagnosis,
          title: this.$t(cardsName.workspace_diagnosis),
          description: this.$t(
            modulesDetails.workspace_diagnosis.short_description
          ),
          path: "/workspace-checklist",
          key: modules.workspace_diagnosis,
          icon: "mdi-format-list-checks",
        },
        {
          name: modules.inventory,
          title: this.$t(cardsName.inventory),
          description: this.$t(modulesDetails.inventory.short_description),
          path: "/inventory",
          key: modules.inventory,
          beta: true,
        },
        {
          name: modules.companyProfile,
          title: this.$t(cardsName.company),
          description: this.$t(
            modulesDetails.company_profile.short_description
          ),
          path: "/company",
          key: modules.companyProfile,
        },
        {
          name: modules.manage_admins,
          title: this.$t(cardsName.manage_admins),
          description: this.$t(modulesDetails.manage_admins.short_description),
          path: "/admin",
          key: modules.manage_admins,
        },
        {
          name: modules.reseller,
          title: this.$t(cardsName.reseller),
          description: this.$t(modulesDetails.reseller.short_description),
          path: "/reseller/clients",
          hidden: !this.isReseller,
          key: modules.reseller,
        },
        {
          name: modules.track,
          title: this.$t(cardsName.track),
          description: this.$t(modulesDetails.conecta_track.short_description),
          path: "/track/users",
          hidden: !this.hasConectaTrack,
          key: modules.track,
        },
        {
          name: modules.sign,
          title: this.$t(cardsName.sign),
          description: this.$t(modulesDetails.conecta_sign.short_description),
          path: "/sign",
          key: modules.sign,
        },
        {
          name: modules.control,
          title: this.$t(cardsName.control),
          description: this.$t(
            modulesDetails.conecta_control.short_description
          ),
          path: null,
          key: modules.control,
        },
        {
          name: modules.reports,
          title: this.$t(cardsName.reports),
          description: this.$t(modulesDetails.reports.short_description),
          path: "/reports",
          key: modules.reports,
        },
        {
          name: modules.billing,
          title: this.$t(cardsName.billing),
          description: this.$t(modulesDetails.billing.short_description),
          hidden: !this.hasContractedPlans,
          path: "/billing/plans",
          key: modules.billing,
        },
        {
          name: modules.contact_us,
          title: this.$t(cardsName.contact_us),
          description: this.$t(modulesDetails.contact_us.short_description),
          path: "/contact",
          key: modules.contact_us,
        },
      ].filter((item) => !item.hidden);
    },

    missingScopesText() {
      return `https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
    },

    progress() {
      const { total_users, total_users_created } = this.firstSyncUsersStatus;
      return (total_users_created / total_users) * 100;
    },
  },

  methods: {
    isProduct,

    showOnboardingModuleOrSetPath(code, path, title) {
      const { main_domain } = this.company;

      const analytics = getAnalytics();
      logEvent(analytics, "navigated_by", {
        code,
        path,
        title,
        navigation_by: "HomePage",
        main_domain,
      });

      // Mesmo sem permissão ao control, há o redirecionamento.
      if (code === "conecta_control") {
        goToControl();
        return;
      }

      this.setPath(path);
    },

    setPath(path) {
      if (path != null) {
        this.$router.push({ path: path });
      }
    },
  },
};
</script>
