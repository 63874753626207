<template>
  <v-dialog
    v-model="dialogOnboarding"
    min-width="340"
    max-width="500"
    persistent
  >
    <v-card class="text-center pa-8">
      <v-card-title class="justify-center display-2 font-weight-normal">
        {{ $t("tour.welcome") }}
      </v-card-title>
      <v-img
        src="@/assets/appslogos/full/conecta_suite_full.png"
        max-width="400"
        class="mx-auto my-2"
      />
      <v-btn
        color="#311F50"
        class="text-capitalize font-weight-regular mt-6"
        dark
        block
        @click="openOnBoard()"
        >{{ $t("tour.startTour") }}</v-btn
      >
      <v-btn
        color="#311F50"
        class="text-capitalize font-weight-regular mt-2"
        dark
        block
        text
        @click="disable()"
        >{{ $t("tour.skipTour") }}</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { disableOnboarding } from "@/helpers/services/onboarding";
import { mapMutations } from "vuex";
export default {
  name: "FirstTimeOnBoarding",
  data: () => ({
    dialogOnboarding: JSON.parse(localStorage.getItem("show_onboarding")),
  }),

  methods: {
    ...mapMutations(["showOnBoarding", "tourOnBoardingRead"]),
    disable() {
      disableOnboarding();
      this.dialogOnboarding = false;
      this.tourOnBoardingRead(true);
    },
    openOnBoard() {
      this.showOnBoarding();
      this.dialogOnboarding = false;
    },
  },
};
</script>
